import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate, useLocation } from 'react-router-dom'
import TopRankingItem from '../components/top-ranking-item';

const Top10Ranking = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const key = currentUser.data.uid + "_ct";
    const c1i = currentUser.data.uid + "_c1";
    const c2i = currentUser.data.uid + "_c2";
    const { state } = useLocation();
    const locked = state ? state.locked : false;

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Top10", key);
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        const lock = await setLocked(currentUser.uid, "top10_locked");
        if (lock === "success") {
            navigate("/");
        } else {
            alert(lock);
        }
    }

    const alertDialog = () => {
        var res = window.confirm("Are you sure you want to finalize rating?");
        if (res) {onClickHandler();}
    }

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-4 has-text-centered'>Top 10</p>
            <p className='is-size-2 has-text-weight-bold has-text-centered'>Intelligence — 50%</p>
            <p className='is-size-2 has-text-weight-bold has-text-centered mb-6'>Beauty — 50%</p>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-borderless">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Image</th>
                            <th className='has-text-left'>Name</th>
                            <th>Intelligence</th>
                            <th>Beauty</th>
                            <th>% Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i][key] === contestant[key]) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;
                            
                            return (
                                <TopRankingItem contestant={contestant} rank={pos} key={contestant.id} score={contestant[key]} c1={contestant[c1i]} c2={contestant[c2i]}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                <div className='columns my-6'>
                {locked ? <button type="button" className="is-fullwidth button is-info block is-large column is-12" onClick={() => {navigate("/")}}>Return to Home</button> : <Fragment><button type="button" className="is-fullwidth button is-info block is-large column is-2" onClick={() => {navigate("/top10/")}}>Edit</button>
                    <button type="button" className="is-fullwidth button is-danger block is-large column is-9 is-offset-1" onClick={alertDialog}>Finalize</button></Fragment>}
                </div>
        </div>
    )
}

export default Top10Ranking;
import { useState, useEffect, useContext } from 'react';
import { checkLocked, getContestants, setContestantScores } from '../utils/firebase';
import ScoringItem from '../components/scoring-item';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'

const Scoring = () => {
    const [list, setList = () => []] = useState([]);
    const [scores, setScores] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();
    const key = currentUser.data.type === "superadmin" ? category : currentUser.data.uid + "_" + category;
    const [locked, setLocked] = useState(false);

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Contestants", "number");
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        const arr = [];
        list.forEach((item) => {
            arr.push(item[key] ? item[key] : "");
        });
        setScores(arr);
    }, [list]);

    useEffect(() => {
        async function check() {
            const locked = await checkLocked(currentUser.uid, category + "_locked");
            setLocked(locked);
        };
        if (currentUser.data.type === "judge") {
            check();
        }
    }, []);

    const onValueChange = (event) => {
        const { id, value } = event.target;

        scores[id] = value;
        setScores([...scores]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (locked) {
            navigate("/preliminaries/" + category + "/ranking", {state: {locked: locked}});
            return;
        }

        const response = await setContestantScores(key, list, scores);

        if (response === "success") {
            navigate("/preliminaries/" + category + "/ranking", {state: {locked: locked}});
        } else {
            alert(response);
        }
    }

    var cat = "";
    var per = "";
    switch(category) {
        case "c1":
            cat = "Festival Costume";
            per = "15%";
            break;
        case "c2":
            cat = "Production Number";
            per = "15%";
            break;
        case "c3":
            cat = "Long Gown Competition";
            per = "20%";
            break;
        case "c4":
            cat = "Close Door Interview";
            per = "30%";
            break;
        case "c5":
            cat = "Swim Suit Competition";
            per = "20%";
            break;
    }

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-4 has-text-centered'>Preliminaries</p>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>{cat} — {per}</h1>
            <form onSubmit={handleSubmit}>
                <div className='columns is-multiline'>
                    {scores.length > 0 ? list.map((contestant, index) => {
                            return (
                                <ScoringItem key={contestant.id} contestant={contestant} index={index} score={scores[index]} onValueChange={onValueChange} locked={locked}/>
                            )
                    }) : ""}
                </div>
                <button type="submit" className="is-fullwidth button is-info block is-large my-6">{locked ? "View Ranking" : "Submit"}</button>
            </form>
        </div>
    )
}

export default Scoring;
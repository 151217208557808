import { useState, useEffect, useContext } from 'react';
import { getJudges, resetJudges, setJudgesName } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate } from 'react-router-dom'
import JudgesItem from '../components/judges-item';

const Judges = () => {
    const [list, setList = () => []] = useState([]);
    const [names, setNames] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        async function getDocs() {
            const docs = await getJudges();
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        const arr = [];
        list.forEach((item) => {
            arr.push(item.name ? item.name : "");
        });
        setNames(arr);
    }, [list]);

    const onValueChange = (event) => {
        const { id, value } = event.target;

        names[id] = value;
        setNames([...names]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await setJudgesName(list, names);
        alert(response);
    }

    const reset = async() => {
        const response = await resetJudges(list);
        if (response === "success") {
            navigate("/");
        } else {
            alert(response);
        }
    }

    const onAlert = () => {
        const person = prompt("Confirm Password", "");
        if (person === "secretresetpass") {
            reset();
        } else {
            alert("Incorrect Password");
        }
    }

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-4 has-text-centered'>-</p>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>Judges</h1>
            <form onSubmit={handleSubmit}>
                <div className='columns is-multiline'>
                    {names.length > 0 ? list.map((judge, index) => {
                            return (
                                <JudgesItem key={judge.id} judge={judge} index={index} name={names[index]} onValueChange={onValueChange}/>
                            )
                    }) : ""}
                </div>
                <button type="submit" className="is-fullwidth button is-info block is-large my-6">Submit</button>

                <button type="button" className="is-fullwidth button is-danger block is-large column is-12 mb-6" onClick={onAlert}>Reset</button>
            </form>
        </div>
    )
}

export default Judges;
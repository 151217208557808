import FormInput from './form-input'
import contestant1 from '../images/1.jpg'
import contestant2 from '../images/2.jpg'
import contestant3 from '../images/3.jpg'
import contestant4 from '../images/4.jpg'
import contestant5 from '../images/5.jpg'
import contestant6 from '../images/6.jpg'
import contestant7 from '../images/7.jpg'
import contestant8 from '../images/8.jpg'
import contestant9 from '../images/9.jpg'
import contestant10 from '../images/10.jpg'
import contestant11 from '../images/11.jpg'
import contestant12 from '../images/12.jpg'
import contestant13 from '../images/13.jpg'
import contestant14 from '../images/14.jpg'
import contestant15 from '../images/15.jpg'
import contestant16 from '../images/16.jpg'
import contestant17 from '../images/17.jpg'
import contestant18 from '../images/18.jpg'

const ScoringItem = ({contestant, score, index, onValueChange, locked}) => {
    const {name, number} = contestant;

    var img;
    switch(number) {
        case 1:
            img = contestant1;
            break;
        case 2:
            img = contestant2;
            break;
        case 3:
            img = contestant3;
            break;
        case 4:
            img = contestant4;
            break;
        case 5:
            img = contestant5;
            break;
        case 6:
            img = contestant6;
            break;
        case 7:
            img = contestant7;
            break;
        case 8:
            img = contestant8;
            break;
        case 9:
            img = contestant9;
            break;
        case 10:
            img = contestant10;
            break;
        case 11:
            img = contestant11;
            break;
        case 12:
            img = contestant12;
            break;
        case 13:
            img = contestant13;
            break;
        case 14:
            img = contestant14;
            break;
        case 15:
            img = contestant15;
            break;
        case 16:
            img = contestant16;
            break;
        case 17:
            img = contestant17;
            break;
        case 18:
            img = contestant18;
            break;
    }
    return (
        <div className="column is-4">
            <div className="card">
                <div className="card-image">
                    <figure className="image is-4by5" style={{overflow: "hidden"}}>
                        <img src={img} alt={name} style={{height: "initial"}}/>
                    </figure>
                </div>
                <div className="card-content">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-size-3">#{number} {name}</p>
                            
                            <FormInput disabled={locked} additionalClasses="mt-5 mb-3" type="number" required id={index} value={score} onChange={(e) => {onValueChange(e)}} step="0.01" min="1" max="10" label="Score"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScoringItem;
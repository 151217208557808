import { useState, useEffect, useContext } from 'react';
import { checkLocked, getContestants, setTopContestantScores } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate } from 'react-router-dom'
import TopScoringItem from '../components/top-scoring-item';

const Top10Scoring = () => {
    const [list, setList = () => []] = useState([]);
    const [c1s, setC1s] = useState([]);
    const [c2s, setC2s] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const c1i = currentUser.data.uid + "_c1";
    const c2i = currentUser.data.uid + "_c2";
    const ct = currentUser.data.uid + "_ct";
    const [locked, setLocked] = useState(false);

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Top10", "number");
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        const c1 = [];
        const c2 = [];
        list.forEach((item) => {
            c1.push(item[c1i] ? item[c1i] : "");
            c2.push(item[c2i] ? item[c2i] : "");
        });
        setC1s(c1);
        setC2s(c2);
    }, [list]);

    useEffect(() => {
        async function check() {
            const locked = await checkLocked(currentUser.uid, "top10_locked");
            setLocked(locked);
        };
        if (currentUser.data.type === "judge") {
            check();
        }
    }, []);

    const onC1ValueChange = (event) => {
        const { name, value } = event.target;

        c1s[name] = value;
        setC1s([...c1s]);
    }

    const onC2ValueChange = (event) => {
        const { name, value } = event.target;

        c2s[name] = value;
        setC2s([...c2s]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (locked) {
            navigate("/top10/ranking", {state: {locked: locked}});
            return;
        }

        const response = await setTopContestantScores("Top10", c1i, c2i, ct, list, c1s, c2s, 0.5, 0.5);

        if (response === "success") {
            navigate("/top10/ranking", {state: {locked: locked}});
        } else {
            alert(response);
        }
    }

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-4 has-text-centered'>Top 10</p>
            <p className='is-size-2 has-text-weight-bold has-text-centered'>Intelligence — 50%</p>
            <p className='is-size-2 has-text-weight-bold has-text-centered mb-6'>Beauty — 50%</p>
            <form onSubmit={handleSubmit}>
                <div className='columns is-multiline'>
                    {c1s.length > 0 && c2s.length > 0 ? list.map((contestant, index) => {
                            return (
                                <TopScoringItem locked={locked} key={contestant.id} contestant={contestant} index={index} c1={c1s[index]} c2={c2s[index]} onC1ValueChange={onC1ValueChange} onC2ValueChange={onC2ValueChange}/>
                            )
                    }) : ""}
                </div>
                <button type="submit" className="is-fullwidth button is-info block is-large my-6">{locked ? "View Ranking" : "Submit"}</button>
            </form>
        </div>
    )
}

export default Top10Scoring;
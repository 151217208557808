import { useState, useEffect, useContext } from 'react';
import { deleteContestants, getContestants, setContestantsScore } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'
import ResultsTop10tem from '../components/results-top10-item';

const ResultsTop5 = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Top5", "number");
            setList(docs);
        };
        getDocs();
    }, []);

    const onClickHandler = async() => {
        if (list.length === 0) {
            alert("No data to finalize");
            return;
        }

        if (!list[0].score) {
            alert("Insufficient data");
            return;
        }

        const response = await setContestantsScore("Top5", list);

        if (response === "success") {
            navigate("/ranking/top5");
        } else {
            alert(response);
        }
    }

    const reset = async() => {
        const response = await deleteContestants("Top5", list);
        if (response === "success") {
            navigate("/");
        } else {
            alert(response);
        }
    }

    const onAlert = () => {
        const person = prompt("Confirm Password", "");
        if (person === "secretresetpass") {
            reset();
        } else {
            alert("Incorrect Password");
        }
    }

    return (
        <div className='column is-12'>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>Top 5</h1>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="3">#</th>
                            <th className='has-text-left' rowSpan="3">Name</th>
                            <th colSpan="10">Judges</th>
                            <th rowSpan="2" colSpan="3">C1<br/><small>Intelligence<br/>(45%)</small></th>
                            <th rowSpan="2" colSpan="3">C2<br/><small>Beauty<br/>(55%)</small></th>
                            <th rowSpan="3">Total %</th>
                        </tr>

                        <tr>
                            <th colSpan="2">1</th>
                            <th colSpan="2">2</th>
                            <th colSpan="2">3</th>
                            <th colSpan="2">4</th>
                            <th colSpan="2">5</th>
                        </tr>

                        <tr>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <th>T</th>
                            <th>A</th>
                            <th>%</th>
                            <th>T</th>
                            <th>A</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            return (
                                <ResultsTop10tem contestant={contestant} key={contestant.id} c1per={4.5} c2per={5.5}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 my-6" onClick={onClickHandler}>Finalize</button>

                    <button type="button" className="is-fullwidth button is-danger block is-large column is-8 is-offset-2 mb-6" onClick={onAlert}>Reset</button>
        </div>
    )
}

export default ResultsTop5;
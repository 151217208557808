import contestant1 from '../images/1.jpg'
import contestant2 from '../images/2.jpg'
import contestant3 from '../images/3.jpg'
import contestant4 from '../images/4.jpg'
import contestant5 from '../images/5.jpg'
import contestant6 from '../images/6.jpg'
import contestant7 from '../images/7.jpg'
import contestant8 from '../images/8.jpg'
import contestant9 from '../images/9.jpg'
import contestant10 from '../images/10.jpg'
import contestant11 from '../images/11.jpg'
import contestant12 from '../images/12.jpg'
import contestant13 from '../images/13.jpg'
import contestant14 from '../images/14.jpg'
import contestant15 from '../images/15.jpg'
import contestant16 from '../images/16.jpg'
import contestant17 from '../images/17.jpg'
import contestant18 from '../images/18.jpg'

const ResultsTop10tem = ({contestant, rank, onItemAddHandler, active, c1per, c2per}) => {
    const {name, number, id, judge1_c1, judge1_c2, judge2_c1, judge2_c2, judge3_c1, judge3_c2, judge4_c1, judge4_c2, judge5_c1, judge5_c2} = contestant;

    var img;
    switch(number) {
        case 1:
            img = contestant1;
            break;
        case 2:
            img = contestant2;
            break;
        case 3:
            img = contestant3;
            break;
        case 4:
            img = contestant4;
            break;
        case 5:
            img = contestant5;
            break;
        case 6:
            img = contestant6;
            break;
        case 7:
            img = contestant7;
            break;
        case 8:
            img = contestant8;
            break;
        case 9:
            img = contestant9;
            break;
        case 10:
            img = contestant10;
            break;
        case 11:
            img = contestant11;
            break;
        case 12:
            img = contestant12;
            break;
        case 13:
            img = contestant13;
            break;
        case 14:
            img = contestant14;
            break;
        case 15:
            img = contestant15;
            break;
        case 16:
            img = contestant16;
            break;
        case 17:
            img = contestant17;
            break;
        case 18:
            img = contestant18;
            break;
    }

    const c1 = judge1_c1 + judge2_c1 + judge3_c1 + judge4_c1 + judge5_c1;
    const c2 = judge1_c2 + judge2_c2 + judge3_c2 + judge4_c2 + judge5_c2;
    
    const c1a = c1 / 5;
    const c2a = c2 / 5;

    const c1p = c1a * c1per;
    const c2p = c2a * c2per;

    const tot = c1p + c2p;

    contestant["score"] = tot;

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='has-text-weight-medium has-text-left'>{name}</td>
            <td>{judge1_c1}</td>
            <td>{judge1_c2}</td>

            <td>{judge2_c1}</td>
            <td>{judge2_c2}</td>

            <td>{judge3_c1}</td>
            <td>{judge3_c2}</td>

            <td>{judge4_c1}</td>
            <td>{judge4_c2}</td>

            <td>{judge5_c1}</td>
            <td>{judge5_c2}</td>

            <td>{c1 ? c1.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>
            <td>{c2 ? c2.toFixed(2) : ""}</td>
            <td>{c2a ? c2a.toFixed(2) : ""}</td>
            <td>{c2p ? c2p.toFixed(2) + "%" : ""}</td>
            <td>{tot ? tot.toFixed(2) + "%" : ""}</td>
            {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default ResultsTop10tem;
import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, getJudges, setTopContestants } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate } from 'react-router-dom'
import html2canvas from 'html2canvas';
import ResultsCategoryItem from '../components/results-category-item';

const RankingCategory = () => {
    const [list, setList = () => []] = useState([]);
    const [judges, setJudges = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Contestants", "number");
            docs.forEach((contestant) => {
                const score = category === "c4" ? contestant["c4"] : category === "c5" ? contestant["c5"] : contestant["judge1_" + category] + contestant["judge2_" + category] + contestant["judge3_" + category] + contestant["judge4_" + category] + contestant["judge5_" + category];
                contestant["cat_score"] = score;
            })
            docs.sort((a,b) => b.cat_score - a.cat_score);
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        async function getDocs() {
            const docs = await getJudges();
            setJudges(docs);
        };
        getDocs();
    }, []);

    const cat = category.toUpperCase();
    var txt = "";
    var per = 0;

    switch(cat) {
        case "C1":
            txt = "Festival Costume";
            per = 15;
            break;
        case "C2":
            txt = "Production Number";
            per = 15;
            break;
        case "C3":
            txt = "Long Gown Competition";
            per = 20;
            break;
        case "C4":
            txt = "Close Door Interview";
            per = 30;
            break;
        case "C5":
            txt = "Swim Suit Competition";
            per = 20;
            break;
    }

    const onPrintHandler = () => {
        var printContents = document.getElementById("print");
    
        html2canvas(printContents, {scale: 3}).then(function(canvas) {
          var myImage = canvas.toDataURL('image/png');
    
          var mywindow = window.open('PRINT');
    
          mywindow.document.write('<html><head><title>' + document.title  + '</title>');
          mywindow.document.write('</head><body >');
          mywindow.document.write('<img src="' + myImage + '" style="width: 100%"/>');
          mywindow.document.write('</body></html>');
      
          setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
        
            mywindow.print();
            mywindow.close();
          }, 10)
        })
      }

    return (
        <div className='column is-12'>
            <div id='print'>
            <p className='is-size-4 has-text-centered'>{txt}</p>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>Binibining Camarines Norte 2023</h1>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="2">#</th>
                            <th rowSpan="2">Name</th>
                            {category === "c4" || category === "c5" ? "" : <th colSpan="5">Judges</th>}
                            <th rowSpan="1" colSpan={category === "c4" || category === "c5" ? "2" : "3"}>{cat}<br/><small>{txt}<br/>({per}%)</small></th>
                            <th rowSpan="2">Rank</th>
                        </tr>

                        <tr>
                        {category === "c4" || category === "c5" ? "" : <Fragment><th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>T</th></Fragment>}
                            <th>A</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i].cat_score === contestant.cat_score) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;

                            return (
                                <ResultsCategoryItem contestant={contestant} rank={pos} key={contestant.id} percentile={per/10} category={category}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>

                <div className='columns my-6'>
                    {judges.map((judge, index) => {
                        return (
                            <div className='column is-one-fifth' key={judge.uid}><p className='has-text-centered has-text-weight-medium is-size-5' style={{borderBottom: "1px solid #dbdbdb"}}>{judge.name}</p><p className='has-text-centered'>{judge.uid.charAt(0).toUpperCase() + judge.uid.substring(1).toLowerCase()}</p></div>
                        )
                    })}
                </div>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 mt-6" onClick={onPrintHandler}>Print</button>
        </div>
    )
}

export default RankingCategory;
import logo from '../logo.svg';
import { Fragment, useContext, useState } from "react";
import { UserContext } from "../context/user-context";
import { signOutUser } from "../utils/firebase";
import {useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Outlet } from "react-router-dom";

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  const signOutHandler = () => {
    signOutUser();
    navigate('/');
  }

  const onMenuToggle = () => {
    setToggle(!toggle);
  }

  const onNavigate = (link) => {
    navigate(link);
    setToggle(false);
  }

  return (
    <Fragment>
          <nav className="navbar py-5" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" onClick={() => onNavigate("/")}>
      <img src={logo}/>
      <h1 style={{width: 170 + "px"}}>
        <p className="is-size-5 has-text-weight-medium">BBCN2023</p>
        <small>A&A Solutions</small>
        </h1>
    </a>

    <a onClick={onMenuToggle} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  {currentUser ? <div id="navbarBasicExample" className={`navbar-menu ${toggle ? "is-active" : ""}`}>
    <div className='navbar-start'>
      {currentUser.data.type === "superadmin" ?
      
      <div className="navbar-item has-dropdown is-hoverable">
      <a className="navbar-link is-arrowless" onClick={() => {navigate("/results/preliminaries")}}>
        Preliminaries
      </a>

      <div className="navbar-dropdown is-boxed">
        <a className="navbar-item" onClick={() => onNavigate("/ranking/c1")}>
          Festival Costume
        </a>
        <a className="navbar-item" onClick={() => onNavigate("/ranking/c2")}>
          Production Number
        </a>
        <a className="navbar-item" onClick={() => onNavigate("/ranking/c3")}>
          Long Gown Competition
        </a>
        <a className="navbar-item" onClick={() => onNavigate("/ranking/c4")}>
          Close Door Interview
        </a>
        <a className="navbar-item" onClick={() => onNavigate("/ranking/c5")}>
          Swim Suit Competition
        </a>
      </div>
    </div>

      :
      
      <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link is-arrowless">
            Preliminaries
          </a>

          <div className="navbar-dropdown is-boxed">
            <a className="navbar-item" onClick={() => onNavigate("/preliminaries/c1")}>
              Festival Costume
            </a>
            <a className="navbar-item" onClick={() => onNavigate("/preliminaries/c2")}>
              Production Number
            </a>
            <a className="navbar-item" onClick={() => onNavigate("/preliminaries/c3")}>
              Long Gown Competition
            </a>
          </div>
        </div>}

          <a className="navbar-item" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/results/top10") : navigate("/top10")}}>
            Top 10
          </a>

        <a className="navbar-item" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/results/top5") : navigate("/top5")}}>
          Top 5
        </a>

        {currentUser.data.type === "superadmin" ? <a className="navbar-item" onClick={() => onNavigate("/judges")}>
              Judges
            </a> : ""}
    </div>
    <div className="navbar-end">
      <div className="navbar-item">
        <span className='has-text-weight-medium'>{currentUser.data.uid.toUpperCase()}</span>
      </div>

      <div className="navbar-item">
        <div className="buttons">
          <a className="button is-info" onClick={signOutHandler}>
            Sign out
          </a>
        </div>
      </div>
    </div>
  </div> : ""}
</nav>
          <Outlet/>
    </Fragment>
  )
}

export default Navigation;

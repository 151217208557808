import { useContext } from 'react';
import { UserContext } from "../context/user-context";
import { useNavigate } from 'react-router-dom'
import banner from '../images/banner.jpg'

const Home = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className=''>
            <img src={banner} alt='banner' style={{width: 100 +"%"}}/>            
            {/* <div className="tile is-ancestor">
                <div className="tile is-5 is-vertical is-parent">
                    <div className="tile is-child notification is-primary" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/results/preliminaries") : navigate("/preliminaries/c1")}}>
                    <p className="title">Preliminaries</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    </div>
                    <div className="tile is-child notification is-warning" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/results/top10") : navigate("/top10")}}>
                    <p className="title">Top 10</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    </div>
                </div>
                <div className="tile is-parent">
                    <div className="tile is-child notification is-info" onClick={() => {currentUser.data.type === "superadmin" ? navigate("/results/top5") : navigate("/top5")}}>
                    <p className="title">Top 5</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Home;
import logo from './logo.svg';
import './App.scss';
import 'bulma/css/bulma.min.css';
import { useContext } from 'react';
import { UserContext } from './context/user-context';
import { Routes, Route } from "react-router-dom";
import Navigation from './routes/navigation';
import Home from './routes/home';
import SignIn from './routes/siginin';
import Scoring from './routes/scoring';
import Ranking from './routes/ranking';
import ResultsPreliminaries from './routes/results-preliminaries';
import RankingPreliminaries from './routes/ranking-preliminaries';
import Top10Scoring from './routes/top10-scoring';
import Top10Ranking from './routes/top10-ranking';
import Top5Scoring from './routes/top5-scoring';
import Top5Ranking from './routes/top5-ranking';
import ResultsTop10 from './routes/results-top10';
import RankingTop10 from './routes/ranking-top10';
import ResultsTop5 from './routes/results-top5';
import RankingTop5 from './routes/ranking-top5';
import Judges from './routes/judges';
import RankingCategory from './routes/ranking-category';

function App() {
  const { currentUser } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={currentUser ? <Home/> : <SignIn/>}/>
        <Route path='/preliminaries/:category' element={currentUser ? <Scoring/> : ""}/>
        <Route path='/preliminaries/:category/ranking' element={currentUser ? <Ranking/> : ""}/>
        <Route path='/top10' element={currentUser ? <Top10Scoring/> : ""}/>
        <Route path='/top10/ranking' element={currentUser ? <Top10Ranking/> : ""}/>
        <Route path='/top5' element={currentUser ? <Top5Scoring/> : ""}/>
        <Route path='/top5/ranking' element={currentUser ? <Top5Ranking/> : ""}/>
        
        <Route path='/ranking/:category' element={currentUser ? currentUser.data.type === "superadmin" ? <RankingCategory/> : "" : ""}/>
        <Route path='/results/preliminaries' element={currentUser ? currentUser.data.type === "superadmin" ? <ResultsPreliminaries/> : "" : ""}/>
        <Route path='/ranking/preliminaries' element={currentUser ? currentUser.data.type === "superadmin" ? <RankingPreliminaries/> : "" : ""}/>
        <Route path='/results/top10' element={currentUser ? currentUser.data.type === "superadmin" ? <ResultsTop10/> : "" : ""}/>
        <Route path='/ranking/top10' element={currentUser ? currentUser.data.type === "superadmin" ? <RankingTop10/> : "" : ""}/>
        <Route path='/results/top5' element={currentUser ? currentUser.data.type === "superadmin" ? <ResultsTop5/> : "" : ""}/>
        <Route path='/ranking/top5' element={currentUser ? currentUser.data.type === "superadmin" ? <RankingTop5/> : "" : ""}/>
        <Route path='/judges' element={currentUser ? currentUser.data.type === "superadmin" ? <Judges/> : "" : ""}/>
      </Route>
    </Routes>
  );
}

export default App;
